export function getInitials(input: string): string {
  if (!input) return "";
  const names = input.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function parsePage(page: any): number {
  const res = parseInt(page);
  if (isNaN(res)) {
    return 1;
  }
  return res;
}

export function parseCategoriesSlug(slug: string): string {
  if (!slug) {
    return "all";
  }
  return slug;
}

export function parseId(id: any): number {
  const res = parseInt(id);
  if (isNaN(res)) {
    return -1;
  }
  return res;
}

export function countPages(totalItems: number, pageSize: number): number {
  return Math.ceil(totalItems / pageSize);
}

export function isValidTab(tabs: any, tab: string | string[]): boolean {
  if (Object.values(tabs).includes(tab)) {
    return true;
  }
  return false;
}
