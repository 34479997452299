
import { CookieManager } from "@/utils/cookie";
import { defineComponent } from "@vue/runtime-core";
import CookieModal from "./CookieModal.vue";
import "./styles.css";
export default defineComponent({
  components: { CookieModal },
  data() {
    return {
      isShowCookieModal: false,
      isShow: false,
      functionalityStorage: false,
      analyticsStorage: false,
      adStorage: false,
    };
  },

  methods: {
    showCookieModal() {
      this.isShowCookieModal = !this.isShowCookieModal;
    },
    onClose() {
      this.isShowCookieModal = false;
      this.isShow = false;
    },
    onRejectAll() {
      CookieManager.rejectAll();
      this.onClose();
    },
    onAcceptAll() {
      CookieManager.acceptAll();
      this.onClose();
    },
  },
  mounted() {
    if (!CookieManager.hasCookieConsent) {
      this.isShow = true;
    }
  },
});
