<template>
  <section class="world space">
    <div class="container">
      <div class="world_cont">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="world_cont_title">
              <h2 class="heading">
                Connecting the world with next generation content
              </h2>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="world_cont_desc">
              <p>
                We firmly believe that we can inspire people and allow for
                profound conversations by using our topics. Creating a more
                connected world through a mixture of different cultures and
                languages. Getting people closer to each other through our
                topics is our innermost motivation, the drive for our platform.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="world_cont_img">
        <img
          src="@/assets/images/home-wordl_img.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
