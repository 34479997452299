
import { defineComponent } from "@vue/runtime-core";
import "./styles.css";

export default defineComponent({
  props: {
    show: Boolean,
  },
  data() {
    return {
      display: true,
      fadeout: false,
    };
  },
  watch: {
    show: async function () {
      if (this.show) {
        this.display = true;
        this.fadeout = false;
      } else {
        this.fadeout = true;
        this.display = true;
        setTimeout(() => {
          this.display = false;
          this.fadeout = false;
        }, 1000);
      }
    },
  },
});
