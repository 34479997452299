
import { auth } from "@/services/firebase";
import { getAuthToken } from "@/utils/auth";
import { sendEmailVerification } from "@toppick/common/build/api/user";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  data() {
    return {
      isEmailSent: false,
      isLoading: false,
    };
  },
  methods: {
    async onLogout() {
      try {
        await auth.signOut();
      } catch (error) {
        console.log(error);
      }
    },
    async sendEmailVerification() {
      this.isLoading = true;
      try {
        await sendEmailVerification(await getAuthToken());
        this.isEmailSent = true;
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  computed: {
    show() {
      return !this.$store.getters.getIsEmailVerified;
    },
    email() {
      return this.$store.getters.getEmail;
    },
  },
});
