<template>
  <section class="new_things">
    <div class="container">
      <div class="new_things_wrap">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="new_things_wrap space">
              <div class="cmn_heading_area">
                <h2 class="heading">
                  Find the way to learn <br /><span class="text-white"
                    >new things</span
                  >
                </h2>
                <p class="small_desc text-dark montM">
                  The Question and Topic Pickers are two convenient tools that
                  will allow you to discover our best topics and questions in a
                  few clicks, thus allowing you to quickly acquire a
                  considerable amount of knowledge regarding a topic which you
                  can discuss with anyone you feel like talking to.
                </p>
                <div class="question_links">
                  <router-link
                    :to="{ name: 'QuestionPickerStart' }"
                    class="cm-btn"
                    >Question Picker</router-link
                  >
                  <router-link :to="{ name: 'TopicPickerStart' }" class="cm-btn"
                    >Topic Picker</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 app_screen">
            <div class="new_things_images">
              <img :src="homeNewThings" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      homeNewThings: require("@/assets/images/home-new_things_img.svg"),
      appStoreSvg: require("@/assets/images/App Store Badge.svg"),
      googlePlaySvg: require("@/assets/images/Google Play Badge.svg"),
    };
  },
};
</script>
