
import { getRecentPosts } from "@toppick/common/build/api/blog";
import { FeaturedBlogPost } from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";
import BlogCardLatest from "@/components/blog/BlogCardLatest.vue";

const RECENT_POST_COUNTER = 3;

export default defineComponent({
  components: { BlogCardLatest },
  data() {
    return {
      accessTime: require("@/assets/images/blog-access_time.svg"),
      blogs: [] as FeaturedBlogPost[],
    };
  },
  async mounted() {
    try {
      const blogs = await getRecentPosts(RECENT_POST_COUNTER);
      this.blogs = blogs;
    } catch (error) {
      console.log(error);
    }
  },
});
