<template>
  <Carousel
    :settings="settings"
    :breakpoints="breakpoints"
    v-if="categories.length > 0"
  >
    <Slide v-for="category in categories" v-bind:key="category.id">
      <div class="carousel__item cg-carousel">
        <carousel-card
          :title="category.title"
          :image="category.image"
          @click="$emit('onSelect', category.slug)"
        />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import CarouselCard from "./CarouselCarouselCard.vue";

export default defineComponent({
  name: "Breakpoints",
  components: {
    Carousel,
    Slide,
    Navigation,
    CarouselCard,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
      itemsToScroll: 1,
      padding: "15px",
    },
    breakpoints: {
      0: {
        itemsToShow: 1.5,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      400: {
        itemsToShow: 2.5,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      575: {
        itemsToShow: 3.5,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      700: {
        itemsToShow: 4.5,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      900: {
        itemsToShow: 5.5,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      1024: {
        itemsToShow: 6.5,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      1199: {
        itemsToShow: 7.5,
        snapAlign: "center",
        itemsToScroll: 1,
      },
    },
  }),
});
</script>
