
import { defineComponent } from "vue";
import CookieBanner from "./components/cookie/CookieBanner.vue";
import FooterComponent from "./components/ui/Footer.vue";
import NavBar from "./components/ui/NavBar.vue";
export default defineComponent({
  name: "App",
  props: {},
  components: {
    NavBar,
    FooterComponent,
    CookieBanner,
  },
});
