
import { PropType, defineComponent } from "vue";
import { FeaturedBlogPost } from "@toppick/common/build/interfaces";
import moment from "moment";
import "./styles.css";
export default defineComponent({
  props: {
    blog: {
      type: Object as PropType<FeaturedBlogPost>,
      required: true,
    },
  },
  data() {
    return {
      RightArrow: require("@/assets/images/right-arrow-black.svg"),
      blogAvar: require("@/assets/images/blog-avtar.svg"),
      accessTime: require("@/assets/images/blog-access_time.svg"),
    };
  },
  computed: {
    displayedTime(): string {
      return moment(this.blog.createdAt).format("L");
    },
  },
});
