
import { defineComponent, PropType } from "vue";
import SearchBarCard from "./SearchBarCard.vue";
import { TopicPageTab } from "@/interfaces/ui";
import { TopicFeatured } from "@toppick/common/build/interfaces";

export default defineComponent({
  components: { SearchBarCard },
  props: {
    searchResults: {
      type: Array as PropType<TopicFeatured[]>,
      required: true,
    },
  },
  data() {
    return {
      searchIcon: require("@/assets/images/search_icon.svg"),
      isOpen: false,
      searchText: "",
    };
  },
  methods: {
    toggleBar() {
      this.isOpen = !this.isOpen;
    },
    onClickTopic(slug: string) {
      this.$router.push({
        name: "Topic",
        params: { slug, tab: TopicPageTab.QUESTION },
      });
    },
  },
  computed: {
    searchBarOpened: function (): string {
      return this.searchText ? "search_bar_opened" : "search_bar_closed";
    },
  },
  watch: {
    searchText: async function (text: string) {
      this.isOpen = text ? true : false;
      this.$emit("onSearch", text);
    },
  },
});
