<template>
  <footer class="footer">
    <div class="footer_inner py-5">
      <div class="container">
        <div class="footer_blocks">
          <div class="row">
            <div class="col-md-4 mb-4">
              <div class="footer_box">
                <a href="#" class="sairaM footer_logo"
                  >Top<span class="cm-color">Pick</span></a
                >
                <p>
                  TopPick is a next-generation platform for finding engaging
                  topics for conversation, language learning, writing, and much,
                  much more...
                </p>
              </div>
            </div>
            <div class="col mb-4 footer_cmn_block">
              <div class="footer_box">
                <h5 class="sairaM footer_head">Navigation</h5>
                <ul class="footer_menu">
                  <li>
                    <router-link :to="{ name: 'Home' }">Home</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'Category',
                        params: {
                          slug: 'all',
                        },
                      }"
                      >Library</router-link
                    >
                  </li>
                  <li v-if="isAuthenticated">
                    <router-link
                      :to="{ name: 'MyTopics', params: { tab: 'me' } }"
                      >Topics</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Blog' }">Blog</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'About' }">About</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright cm_bg py-3">
      <div class="container-fluid">
        <p class="text-center text-white montM">
          © 2022 TopPick All Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import "./styles.css";
export default {
  data() {
    return {
      appStoreSvg: require("@/assets/images/App Store Badge.svg"),
      googlePlaySvg: require("@/assets/images/Google Play Badge.svg"),
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
  },
};
</script>
