
import { PropType } from "vue";
import { CategoryFeatured } from "@toppick/common/build/interfaces";
export default {
  props: {
    category: {
      type: Object as PropType<CategoryFeatured[]>,
      required: true,
    },
  },
};
