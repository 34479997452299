
import { CookieManager } from "@/utils/cookie";
import { defineComponent } from "@vue/runtime-core";
import "./styles.css";
export default defineComponent({
  emits: ["onClose"],
  props: {
    show: Boolean,
  },
  data() {
    return {
      isShowMoreDescription: false,
      functionalityStorage: false,
      analyticsStorage: false,
      adStorage: false,
    };
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    showMoreDescription() {
      this.isShowMoreDescription = true;
    },
    onRejectAll() {
      CookieManager.rejectAll();
      this.refreshCookies();
      this.onClose();
    },
    onAcceptAll() {
      CookieManager.acceptAll();
      this.refreshCookies();
      this.onClose();
    },
    refreshCookies() {
      this.functionalityStorage = CookieManager.functionalityStorage;
      this.analyticsStorage = CookieManager.analyticsStorage;
      this.adStorage = CookieManager.adStorage;
    },
    onSavePreference() {
      CookieManager.functionalityStorage = this.functionalityStorage;
      CookieManager.analyticsStorage = this.analyticsStorage;
      CookieManager.adStorage = this.adStorage;
      CookieManager.save();
      this.refreshCookies();
      this.onClose();
    },
  },
  mounted() {
    this.refreshCookies();
  },
});
