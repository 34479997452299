import { UserFeatured, UserRole } from "@toppick/common/build/interfaces";
import { GetterTree } from "vuex";
import { DEFAULT_LANGUAGE, State } from "./state";

export type Getters = {
  getUsername(state: State): string;
  getUserPhoto(state: State): string;
  getUserID(state: State): string;
  getIsAuthenticated(state: State): boolean;
  getIsEmailVerified(state: State): boolean;
  getUserRole(state: State): UserRole;
  getUserFeatured(state: State): UserFeatured;
  getEmail(state: State): string;
  getContentLanguage(state: State): string;
  isTranslateContent(state: State): boolean;
};

export const getters: GetterTree<State, State> & Getters = {
  getUsername: (state) => {
    return state.user.displayName;
  },
  getUserID: (state) => {
    return state.user.uid;
  },
  getUserPhoto: (state) => {
    return state.user.photoURL;
  },
  getIsAuthenticated: (state) => {
    return state.user.isAuthenticated;
  },
  getIsEmailVerified: (state) => {
    return state.user.isEmailVerified;
  },
  getUserRole: (state) => {
    return state.user.role;
  },
  getEmail: (state) => {
    return state.user.email;
  },
  getUserFeatured: (state): UserFeatured => {
    return {
      image: state.user.photoURL,
      username: state.user.displayName,
      uid: state.user.uid,
      email: state.user.email,
      role: state.user.role,
    };
  },

  getContentLanguage(state) {
    return state.contentLanguage;
  },
  isTranslateContent(state) {
    return (
      !!state.contentLanguage && state.contentLanguage !== DEFAULT_LANGUAGE
    );
  },
};
