import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import Home from "@/views/home/Index.vue";
import { getTitleFromSlug } from "@/utils/utils";

//Speak Confidently in a Foreign Language
const DEFAULT_TITLE = "General English Topics | TopPick";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/Index.vue"),
    meta: { title: "About Us | TopPick" },
  },

  {
    path: "/blog/all",
    name: "Blog",
    component: () =>
      import(
        /* webpackChunkName: "blog-all" */ "../views/blog/overview/Index.vue"
      ),
    meta: { title: "Blog | TopPick" },
  },

  {
    path: "/blog/:slug",
    name: "BlogDetail",
    component: () =>
      import(
        /* webpackChunkName: "blog-detail" */ "../views/blog/detail/Index.vue"
      ),
    meta: {
      title: (route) =>
        `${getTitleFromSlug(route.params.slug)} | Blog | TopPick`,
    },
  },
  {
    path: "/categories/:slug",
    name: "Category",
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/category/Index.vue"),
    meta: { title: "Explore Our Vast Library | TopPick" },
  },

  {
    path: "/topics/:slug/:tab",
    name: "Topic",
    component: () =>
      import(/* webpackChunkName: "topic" */ "../views/topic/Index.vue"),
    meta: {
      title: (route) =>
        `${getTitleFromSlug(
          route.params.slug
        )} | Interesting Questions | TopPick`,
    },
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/auth/login/Index.vue"),
    meta: {
      hideForAuth: true,
      title: "Login | TopPick",
    },
  },

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "Login" */ "../views/auth/forgot_password/Index.vue"
      ),
    meta: {
      hideForAuth: true,
      title: "Password Reset | TopPick",
    },
  },

  {
    path: "/signup",
    name: "SignUp",
    component: () =>
      import(/* webpackChunkName: "SignUp" */ "../views/auth/signup/Index.vue"),
    meta: {
      hideForAuth: true,
      title: "Sign Up | TopPick",
    },
  },

  {
    path: "/profile/:tab",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/profile/Index.vue"),
    meta: { requiresAuth: true, title: "My Profile | TopPick" },
  },

  {
    path: "/topics/:tab",
    name: "MyTopics",
    component: () =>
      import(/* webpackChunkName: "MyTopics" */ "../views/my_topic/Index.vue"),
    meta: { requiresAuth: true, title: "My Topics | TopPick" },
  },

  {
    path: "/users/:username",
    name: "ViewProfile",
    component: () =>
      import(
        /* webpackChunkName: "ViewProfile" */ "../views/view_profile/Index.vue"
      ),
    meta: {
      requiresAuth: true,
      title: (route) =>
        `${getTitleFromSlug(route.params.username)}'s Profile | TopPick`,
    },
  },

  {
    path: "/contact-us",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ "../views/contact/Index.vue"),
    meta: { requiresAuth: true, title: "Contact Us | TopPick" },
  },

  {
    path: "/maintenance",
    name: "Maintenance",
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ "../views/error/Maintenance.vue"
      ),
    meta: { requiresAuth: true, hideForAuth: true },
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/policy/PrivacyPolicy.vue"
      ),
    meta: { requiresAuth: false, title: "Privacy Policy | TopPick" },
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: () =>
      import(
        /* webpackChunkName: "cookie-policy" */ "../views/policy/CookiePolicy.vue"
      ),
    meta: { requiresAuth: false, title: "Cookie Policy | TopPick" },
  },

  {
    path: "/question-picker/start",
    name: "QuestionPickerStart",
    component: () =>
      import(
        /* webpackChunkName: "question_picker_start" */ "../views/picker/question/start/Index.vue"
      ),
    meta: { requiresAuth: false, title: "Question Picker | TopPick" },
  },

  {
    path: "/question-picker/play",
    name: "QuestionPickerPlay",
    component: () =>
      import(
        /* webpackChunkName: "question_picker_play" */ "../views/picker/question/play/Index.vue"
      ),
  },

  {
    path: "/topic-picker/start",
    name: "TopicPickerStart",
    component: () =>
      import(
        /* webpackChunkName: "topic_picker_start" */ "../views/picker/topic/start/Index.vue"
      ),
    meta: { requiresAuth: false, title: "Topic Picker | TopPick" },
  },

  {
    path: "/topic-picker/play",
    name: "TopicPickerPlay",
    component: () =>
      import(
        /* webpackChunkName: "topic_picker_play" */ "../views/picker/topic/play/Index.vue"
      ),
  },

  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "error-404" */ "../views/error/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from) {
    if (to.path != from.path) {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  const { title } = to.meta;
  document.title =
    typeof title === "function" ? title(to) : title || DEFAULT_TITLE;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const hideForAuth = to.matched.some((record) => record.meta.hideForAuth);
  const isAuthenticated = store.getters.getIsAuthenticated;
  if (process.env.VUE_APP_MAINTENANCE_MODE === "true") {
    to.path !== "/maintenance" ? next("/maintenance") : next();
  } else if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (hideForAuth && isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
