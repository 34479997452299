
import { defineComponent } from "vue";
import {
  getFeaturedTopics,
  searchTopicsByKeyword,
} from "@toppick/common/build/api/topic";
import { TopicFeatured } from "@toppick/common/build/interfaces";
import { TopicPageTab } from "@/interfaces/ui";
import SearchBar from "@/components/topic/SearchBar.vue";
const DISPLAYED_TOPIC_COUNT = 5;
const FEATURED_TOPIC_COUNT = 15;

export default defineComponent({
  components: { SearchBar },
  data() {
    return {
      isBarOpen: false,
      featuredTopics: [] as TopicFeatured[],
      searchResults: [] as TopicFeatured[],
      TopicPageTab,
    };
  },

  methods: {
    async loadData() {
      this.featuredTopics = await getFeaturedTopics({
        count: FEATURED_TOPIC_COUNT,
      });
    },
    async onSearch(text: string) {
      if (text === "") {
        this.searchResults = [];
        return;
      }
      this.searchResults = await searchTopicsByKeyword({
        keyword: text,
        count: DISPLAYED_TOPIC_COUNT,
      });
    },
  },

  async mounted() {
    try {
      this.loadData();
    } catch (error) {
      console.log(error);
    }
  },
});
