export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function parseStringToBoolean(text: any) {
  return text === "true";
}

export function getTitleFromSlug(slug: string): string {
  const words = slug.split("-");
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function parseHtml(html: string): string {
  return (
    new DOMParser().parseFromString(html, "text/html").documentElement
      .textContent || ""
  );
}
