<template>
  <div class="search_result_item">
    <div class="search_result_img">
      <img
        :src="image || imagePlaceholder"
        alt=""
        class="img-fluid searched-image"
      />
    </div>
    <div class="search_result_cont">
      <h6 class="montM text-capitalize">{{ title }}</h6>
      <p>Topic</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
  },

  data() {
    return {
      imagePlaceholder: require("@/assets/images/serch_dummy.png"),
    };
  },
};
</script>
