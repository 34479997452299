import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "home"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_section = _resolveComponent("search-section")!
  const _component_featured_categories_section = _resolveComponent("featured-categories-section")!
  const _component_about_section = _resolveComponent("about-section")!
  const _component_philosophy_section = _resolveComponent("philosophy-section")!
  const _component_latest_topics_section = _resolveComponent("latest-topics-section")!
  const _component_learn_new_things = _resolveComponent("learn-new-things")!
  const _component_world_section = _resolveComponent("world-section")!
  const _component_latest_blogs_section = _resolveComponent("latest-blogs-section")!
  const _component_email_not_confirmed_modal = _resolveComponent("email-not-confirmed-modal")!
  const _component_picker_actions_button = _resolveComponent("picker-actions-button")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_search_section),
          _createVNode(_component_featured_categories_section, { categories: _ctx.featuredCategories }, null, 8, ["categories"]),
          _createVNode(_component_about_section),
          _createVNode(_component_philosophy_section),
          _createVNode(_component_latest_topics_section, {
            topics: _ctx.latestTopics,
            categories: _ctx.featuredCategories
          }, null, 8, ["topics", "categories"]),
          _createVNode(_component_learn_new_things),
          _createVNode(_component_world_section),
          _createVNode(_component_latest_blogs_section)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_email_not_confirmed_modal),
    _createVNode(_component_picker_actions_button),
    _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"]),
    _createVNode(_component_email_not_confirmed_modal),
    _createVNode(_component_picker_actions_button)
  ], 64))
}