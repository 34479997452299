
import { defineComponent, PropType } from "vue";
import { TopicFeatured } from "@toppick/common/build/interfaces";
import moment from "moment";
export default defineComponent({
  props: {
    topic: {
      type: Object as PropType<TopicFeatured>,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    displayedTime() {
      return this.topic ? moment(this.topic.timestamp).format("L") : "";
    },
  },
});
