import { ActionTree, ActionContext } from "vuex";
import firebase from "firebase/compat/app";
import { DEFAULT_USER_APP_STATE, State } from "./state";
import { Mutations, MutationTypes } from "./mutations";

export enum ActionTypes {
  STARTUP = "STARTUP",
  SET_USER = "SET_USER",
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [ActionTypes.SET_USER](
    { commit }: AugmentedActionContext,
    user: firebase.User | null
  ): Promise<void>;
}

export const actions: ActionTree<State, State> & Actions = {
  async [ActionTypes.SET_USER]({ commit }, user: firebase.User | null) {
    if (!user) {
      commit(MutationTypes.SET_USER, DEFAULT_USER_APP_STATE);
    } else if (!user.emailVerified) {
      commit(MutationTypes.SET_USER, {
        ...DEFAULT_USER_APP_STATE,
        isEmailVerified: false,
        email: user.email!,
      });
    } else {
      const { claims } = await user.getIdTokenResult(true);
      commit(MutationTypes.SET_USER, {
        displayName: user.displayName || "",
        isAuthenticated: true,
        photoURL: user.photoURL || "",
        uid: user.uid,
        role: claims.role,
        email: user.email!,
        isEmailVerified: true,
      });
    }
  },
};
