
import { defineComponent } from "vue";
import { getInitials } from "@/utils/ui";
import AuthModal from "../auth/AuthModal.vue";
import UserAvatar from "./UserAvatar.vue";
import { ProfilePageTab } from "@/interfaces/ui";
import "./styles.css";
import { getLatestNews } from "@/services/app";
import { auth } from "@/services/firebase";
export default defineComponent({
  components: { AuthModal, UserAvatar },
  props: {},
  name: "NavBar",
  data() {
    return {
      googlePlaySvg: require("@/assets/images/Google Play Badge.svg"),
      appStoreSvg: require("@/assets/images/App Store Badge.svg"),
      message: "",
      isOpen: false,
      isShowAuthModal: false,
      isAvtarShow: false,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    initials() {
      return getInitials(this.$store.getters.getUsername);
    },
    email() {
      return this.$store.getters.getEmail;
    },
    userPhoto() {
      return getInitials(this.$store.getters.getUserPhoto);
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentUser() {
      return this.$store.getters.getUserFeatured;
    },
  },

  async mounted() {
    this.message = await getLatestNews();
  },
  methods: {
    toggleCard() {
      this.isOpen = !this.isOpen;
    },
    toggleAvtar() {
      this.isAvtarShow = !this.isAvtarShow;
    },
    toggleAuthModal() {
      this.isShowAuthModal = !this.isShowAuthModal;
    },
    closeMenu() {
      this.isOpen = false;
      this.isAvtarShow = false;
    },
    goProfile() {
      this.$router.push({
        name: "Profile",
        params: {
          tab: ProfilePageTab.INFO,
        },
      });
    },
    async onLogout() {
      try {
        await auth.signOut();
        this.$router.push({
          name: "Home",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
