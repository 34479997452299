import { MutationTree } from "vuex";
import { State, UserAppState } from "./state";

export enum MutationTypes {
  SET_USER = "SET_USER",
  SET_CONTENT_LANGUAGE = "SET_CONTENT_LANGUAGE",
  RESET_TRANSLATION = "RESET_TRANSLATION",
}

export interface Mutations<S = State> {
  [MutationTypes.SET_USER](state: S, user: UserAppState): void;
  [MutationTypes.SET_CONTENT_LANGUAGE](state: S, language: string): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_USER](state, user) {
    state.user = user;
  },
  [MutationTypes.SET_CONTENT_LANGUAGE](state, language) {
    state.contentLanguage = language;
  },
};
