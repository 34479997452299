
import { defineComponent } from "vue";
import { CategoryFeatured } from "@toppick/common/build/interfaces";
import { PropType } from "vue";
import CategoryCarousel from "@/components/category/CategoryCarousel.vue";
export default defineComponent({
  components: { CategoryCarousel },
  props: {
    categories: {
      type: Array as PropType<CategoryFeatured[]>,
      required: true,
    },
  },
  data() {
    return {
      carouselRightIcon: require("@/assets/images/carousel-right-arrow.svg"),
    };
  },
  methods: {
    navigateCategories(slug: string) {
      this.$router.push({ name: "Category", params: { slug } });
    },
  },
});
