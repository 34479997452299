import { createApp } from "vue";
import mitt from "mitt";
import { Button } from "ant-design-vue";
import VueSocialSharing from "vue-social-sharing";
import { ToppickLibraryParams } from "@toppick/common";
import { auth } from "./services/firebase";
import store from "./store";
import router from "./router";
import { ActionTypes } from "./store/actions";
import { CookieManager } from "./utils/cookie";
import VueCookies from "vue-cookies";

import "@vueform/multiselect/themes/default.css";
import "./styles/global.css";

import App from "./App.vue";

const app = createApp(App as unknown as Element)
  .use(store)
  .use(VueCookies)
  .use(VueSocialSharing)
  .use(Button);

app.directive("focus", {
  mounted(el) {
    el.focus();
  },
});

CookieManager.init();
ToppickLibraryParams.init({
  hostname: process.env.VUE_APP_API_HOSTNAME!,
  graphql_hostname: process.env.VUE_APP_NEXT_PUBLIC_GRAPHCMS_ENDPOINT!,
});

const eventBus = mitt();
app.config.globalProperties.eventBus = eventBus;
let mounted = false;
auth.onAuthStateChanged(async (user) => {
  await store.dispatch(ActionTypes.SET_USER, user);
  if (!mounted) {
    app.use(router).mount("#app");
    mounted = true;
  }
});
