import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "latest_blog_img" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "latest_topics_box_cont" }
const _hoisted_4 = {
  key: 0,
  class: "badge text-capitalize"
}
const _hoisted_5 = { class: "title text-capitalize" }
const _hoisted_6 = { class: "blog_time montRI" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["latest_topics_box mb-4", { full_width_topic: _ctx.fullWidth }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickTopic')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.topic.image,
        alt: "",
        class: "img-fluid"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.topic.topics_categories.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.topic.topics_categories[0].categories.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.topic.title), 1),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.displayedTime), 1)
    ])
  ], 2))
}