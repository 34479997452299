export interface SideBarItem {
  title: string;
  image: string;
  slug: string;
}

export enum TopicPageTab {
  QUESTION = "questions",
  ARTICLE = "articles",
  VIDEO = "videos",
  IMAGE = "images",
}

export enum TopicsPageTab {
  MY_TOPICS = "me",
  VIEWED = "viewed",
  EDITED = "edited",
  USER_LIST = "list",
}

export enum ProfilePageTab {
  INFO = "info",
  SOCIAL = "social",
  INTERESTS = "interests",
}

export enum ActionStatus {
  DEFAULT,
  ADDED,
  EDITED,
  DELETING_EFF_1,
  DELETING_EFF_2,
  DELETED,
}
