
import { defineComponent, PropType } from "vue";
import LatestTopicCard from "@/components/topic/LatestTopicCard.vue";
import CategoryCard from "@/components/category/CategoryCard.vue";
import { TopicPageTab } from "@/interfaces/ui";
import {
  CategoryFeatured,
  TopicFeatured,
} from "@toppick/common/build/interfaces";
export default defineComponent({
  components: { LatestTopicCard, CategoryCard },
  props: {
    topics: {
      type: Array as PropType<TopicFeatured[]>,
      required: true,
    },
    categories: {
      type: Array as PropType<CategoryFeatured[]>,
      required: true,
    },
  },
  methods: {
    onClickTopic(slug: string) {
      this.$router.push({
        name: "Topic",
        params: { slug, tab: TopicPageTab.QUESTION },
      });
    },
    onClickCategory(slug: string) {
      this.$router.push({
        name: "Category",
        params: { slug },
      });
    },
  },
});
