import { UserRole } from "@toppick/common/build/interfaces";

export interface UserAppState {
  displayName: string;
  photoURL: string;
  uid: string;
  isAuthenticated: boolean;
  role: UserRole;
  isEmailVerified: boolean;
  email: string;
}

export interface State {
  user: UserAppState;
  contentLanguage: string;
}

export const DEFAULT_USER_APP_STATE: UserAppState = {
  displayName: "",
  isAuthenticated: false,
  photoURL: "",
  uid: "",
  email: "",
  role: UserRole.Default,
  isEmailVerified: true,
};

export const DEFAULT_LANGUAGE = "en";

export const state: State = {
  user: DEFAULT_USER_APP_STATE,
  contentLanguage: DEFAULT_LANGUAGE,
};
