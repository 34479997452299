import { parseStringToBoolean } from "./utils";

export abstract class CookieManager {
  public static hasCookieConsent = false;
  public static functionalityStorage = false;
  public static analyticsStorage = false;
  public static adStorage = false;

  public static init(): void {
    this.hasCookieConsent = parseStringToBoolean(
      localStorage.getItem("hasCookieConsent")
    );
    this.functionalityStorage = parseStringToBoolean(
      localStorage.getItem("functionalityStorage")
    );
    this.analyticsStorage = parseStringToBoolean(
      localStorage.getItem("analyticsStorage")
    );
    this.adStorage = parseStringToBoolean(localStorage.getItem("adStorage"));
  }

  private static parseBooleanToStorageValue(value: boolean): string {
    return value ? "granted" : "denied";
  }

  public static acceptAll() {
    this.functionalityStorage = true;
    this.analyticsStorage = true;
    this.adStorage = true;
    this.save();
  }

  public static rejectAll() {
    this.functionalityStorage = false;
    this.analyticsStorage = false;
    this.adStorage = false;
    this.save();
  }

  private static updateCookies(): void {
    window.gtag("consent", "update", {
      functionality_storage: this.parseBooleanToStorageValue(
        this.functionalityStorage
      ),
      analytics_storage: this.parseBooleanToStorageValue(this.analyticsStorage),
      ad_storage: this.parseBooleanToStorageValue(this.adStorage),
    });
  }

  public static save(): void {
    this.updateCookies();
    this.persistInStorage();
  }

  private static persistInStorage(): void {
    localStorage.setItem(
      "functionalityStorage",
      this.functionalityStorage.toString()
    );
    localStorage.setItem("analyticsStorage", this.analyticsStorage.toString());
    localStorage.setItem("adStorage", this.adStorage.toString());
    localStorage.setItem("hasCookieConsent", "true");
  }
}
