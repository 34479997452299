<template>
  <section class="about_outer space">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="about_img">
            <img src="@/assets/images/home-about_img.png" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="about_content">
            <h2 class="tittle_cm">
              What is <span class="text-white">TopPick</span>
            </h2>
            <p class="mt-3 mb-4">
              TopPick is a platform that can improve many aspects of your
              personal and professional life. Discover its uses and features to
              get the best possible experience.
            </p>
            <router-link
              class="cm-btn"
              :to="{
                name: 'BlogDetail',
                params: { slug: 'what-is-toppick' },
              }"
              >Learn more
              <img :src="arrowIcon" alt="" class="ml-1" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      arrowIcon: require("@/assets/images/right-arrow.svg"),
    };
  },

  methods: {
    onClick() {
      this.$router.push({
        name: "Blog",
        params: { slug: "what-is-toppick" },
      });
    },
  },
};
</script>
